
import unytedlogo from '../images/unytedlogo.png';
import './../css/App.css';
import Web3 from 'web3';
import Fortmatic from 'fortmatic';
import { useState } from 'react';
import { NFTCard } from '../components/NFTCard/nftcard';

const App = () => {
  const [step, setStep] = useState(0)
  const [address, setAddress] = useState("")
  const [balance, setBalance] = useState(0)

  const apiKey = "pk_live_85435E93B28ACB8F";
  const fm = new Fortmatic(apiKey);
  const web3 = new Web3(fm.getProvider());
  var showBalance = false;

  let getBalance = () => {
    showBalance = true;
    fm.user.getBalances().then((x) => {
      setBalance(x[0]["crypto_amount"]);
    });
  }
  let signin = () => {
    try {
      console.log("Yeah bithc")
      web3.currentProvider.enable().catch(() => {
        console.log("Test 2")
      });
    }
    catch (e) {
      console.log("Test")
    }
  }

  let handleGetAccounts = () => {
    try {
      var promise = web3.eth.getAccounts();
      promise.then((x) => {
        console.log(x.toString());
        setAddress(x.toString());
        setStep(1);
      });
      promise.catch((e) => {
        setStep(0);
        console.log("error " + e)
      });
    }
    catch (e) {
      console.log("Test")
    }
  }

  let setNextStep = () => { setStep(step + 1); }

  const handleDeposit = () => {
    fm.user.deposit()//[true])
    setStep(2);
  }

  const getActiveButton = () => {
    switch (step) {
      case 0:
        return (<button
          onClick={handleGetAccounts}
          className="App-link"
        > Get a Wallet </button>)
      case 1:
        return (<button
          onClick={setNextStep}
          className="App-link"
        > Show NFT </button>)
      /*case 2:
      return (<><button
        onClick={handleDeposit}
        className="App-link"
      > Obtain more Crypto </button>
        <a href="https://opensea.io/collection/test-2942">
          <button
            className="App-link"
          > Buy a NFT </button>
        </a></>)*/
    }
  }

  const getLine = (text) => {
    return (
      <text className='normalText'>{text}</text>
    )
  }

  const getAllButtons = () => {
    return (<>   <button
      onClick={signin}
      className="App-link"
    > Signin </button>
      <button
        onClick={handleGetAccounts}
        className="App-link"
      > Get a Wallet </button>
      <button
        onClick={handleDeposit}
        className="App-link"
      > Obtain Crypto </button>
      <button
        onClick={getBalance}
        className="App-link"
      > getBalance </button>
      <a href="https://opensea.io/collection/test-2942">
        <button
          className="App-link"
        > Buy a NFT</button></a></>
    )
  }

  return (
    <div className="App">
      <script src="https://cdn.jsdelivr.net/gh/ethereum/web3.js@1.0.0-beta.36/dist/web3.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/fortmatic@latest/dist/fortmatic.js"></script>
      <header className="App-header">
        <img src={unytedlogo} className="App-logo" alt="logo" />
        {
          step >= 2 ? NFTCard() : ""
        }
        {
          step < 1 ? getLine("Welcome to the premium experience.") : ""
        }
        {
          address !== "" ? getLine("Your obtained a wallet!") : ""
        }
        {
          address !== "" ? getLine("Please send us the wallet address so we can send you the NFT") : ""
        }
        {address !== "" ? getLine("") : ""
        }

        {address !== "" ? getLine("Your wallet address is ") : ""
        }
        {address !== "" ? getLine(address) : ""
        }
        {
          getActiveButton()
          //getAllButtons()
        }

      </header>
    </div>
  );
}

export default App;
