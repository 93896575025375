import React from 'react'
import nftImage from '../../images/nft2.png';

export function NFTCard() {

  return (
    <div>
      <div>
        <p className='nftCardText'>Incoming NFT</p>
        <img src={nftImage} className="nftCard" />
      </div>
    </div>
  )
}