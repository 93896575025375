import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './pages/App';
import Web3 from 'web3';
import Fortmatic from 'fortmatic';

const root = ReactDOM.createRoot(document.getElementById('root'));
/*const apiKey = "pk_live_47CF693823595ED6";
const address = "0xBe3Ee15DA986636b35091BBB72FAF3f9dE85b8DE";
const fm = new Fortmatic(apiKey);
const web3 = new Web3(fm.getProvider());
window.web3 = web3;*/

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
